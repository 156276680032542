import Agriculture from './pages/Agriculture/Agriculture';
import Agtech from './pages/Agtech/Agtech';
import BoardPrices from './pages/BoardPrices/BoardPrices';
import ClimaPronostico from './pages/pronostico/Pronostico';
import ClimaLluvias from './pages/lluvias/Lluvias';
import ClimaHumedad from './pages/humedad/Humedad';
import ClimaImagenSatelital from './pages/imagenSatelital/ImagenSatelital';
import Dairying from './pages/Dairying/Dairying';
import DolarPage from './pages/DolarPage/DolarPage';
import StocksPage from './pages/StocksPage/StocksPage';
import CurrenciesPage from './pages/CurrenciesPage/CurrenciesPage';
import EconomyAndFinances from './pages/EconomyAndFinances/EconomyAndFinances';
import EconomicIndicators from './pages/EconomicIndicators/EconomicIndicators';
import FarmSupplies from './pages/FarmSupplies/FarmSupplies';
import FuturesAndOptions from './pages/FuturesAndOptions/FuturesAndOptions';
import Ganaderia from './pages/Ganaderia/Ganaderia';
import GeneralInterest from './pages/GeneralInterest/GeneralInterest';
import Granos from './pages/granos/Granos';
import Home from './pages/home/Home';
import Livestock from './pages/Livestock/Livestock';
import MachinesAndVehicles from './pages/MachinesAndVehicles/MachinesAndVehicles';
import PricesCañuelas from './pages/PricesCañuelas/PricesCañuelas';
import PricesLiniers from './pages/PricesLiniers/PricesLiniers';
import PricesRosario from './pages/PricesRosario/PricesRosario';
import MeasurementConverter from './pages/MeasurementConverter/MeasurementConverter';
import NotFound from './pages/NotFound/NotFound';
import OficialPrices from './pages/OficialPrices/OficialPrices';
import PhysicalMarket from './pages/PhysicalMarket/PhysicalMarket';
import RegionalEconomies from './pages/RegionalEconomies/RegionalEconomies';
import SearchResults from './pages/SearchResults/SearchResults';
import TagsPage from './pages/TagsPage/TagsPage';
import Videos from './pages/videos/Videos';
import FuturesHistoricalData from './pages/HistoricalData/FuturesHistoricalData/FuturesHistoricalData';
import PhysicalHistoricalData from './pages/HistoricalData/PhysicalHistoricalData/PhysicalHistoricalData';
import BoardHistoricalData from './pages/HistoricalData/BoardHistoricalData/BoardHistoricalData';
import CurrenciesHistoricalData from './pages/HistoricalData/CurrenciesHistoricalData/CurrenciesHistoricalData';
import EconomicHistoricalData from './pages/HistoricalData/EconomicHistoricalData/EconomicHistoricalData';
import NewsletterPage from './pages/NewsletterPage/NewsletterPage';
import ExpoAgro from './pages/ExpoAgro/ExpoAgro';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import TermsOfUseBR from './pages/TermsOfUseBR/TermsOfUseBR';
import Streaming from './pages/Streaming/Streaming';
import AapresidConference from './pages/AapresidConference/AapresidConference';
import Agroactiva from './pages/Agroactiva/Agroactiva';
import TodoTrigo from './pages/TodoTrigo/TodoTrigo';
import AgrofyTv from './pages/AgrofyTv/AgrofyTv';
import AgrofyNewsLive from './pages/AgrofyNewsLive/AgrofyNewsLive';
import Categories from './pages/Categories/Categories';
import Cadena3 from './pages/Cadena3/Cadena3';
import AuthorPage from './pages/AuthorPage/AuthorPage';
import Corteva from './pages/Partners/Corteva/Corteva';
import Basf from './pages/Partners/Basf/Basf';
import Metalfor from './pages/Partners/Metalfor/Metalfor';
import Yara from './pages/Partners/Yara/Yara';
import SuccessCases from './pages/SuccessCases/SuccessCases';
import IndicesAgrofy from './pages/IndicesAgrofy/IndicesAgrofy';

const routes = 
{
  esAR: [
    {
      path: '/',
      component: Home,
      exact: true,
      showHeader: true
    },
    {
      path: '/pronostico-clima-tiempo',
      component: ClimaPronostico,
      exact: true,
      showHeader: true
    },
    {
      path: '/pronostico-clima-tiempo/ciudad/:city',
      component: ClimaPronostico,
      exact: true,
      showHeader: true
    },
    {
      path: '/pronostico-clima-tiempo/lluvias-temperaturas',
      component: ClimaLluvias,
      exact: true,
      showHeader: true
    },
    {
      path: '/pronostico-clima-tiempo/humedad',
      component: ClimaHumedad,
      exact: true,
      showHeader: true
    },
    {
      path: '/pronostico-clima-tiempo/imagen-satelital',
      component: ClimaImagenSatelital,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/precio-soja',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/precio-trigo',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/precio-maiz',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/precio-girasol',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/mercado-fisico',
      component: PhysicalMarket,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/precios-oficiales',
      component: OficialPrices,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/futuros-opciones',
      component: FuturesAndOptions,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/precios-pizarra',
      component: BoardPrices,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/series-historicas-futuros',
      component: FuturesHistoricalData,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/series-historicas-fisico',
      component: PhysicalHistoricalData,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/series-historicas-pizarra',
      component: BoardHistoricalData,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/series-historicas-monedas',
      component: CurrenciesHistoricalData,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/series-historicas-indicadores-economicos',
      component: EconomicHistoricalData,
      exact: true,
      showHeader: true
    },
    {
      path: '/videos-de-la-agroindustria',
      component: Videos,
      exact: true,
      showHeader: true
    },
    /* {
      path: '/mercado-ganadero',
      component: MarketPrices,
      exact: true,
      showHeader: true
    }, */
    {
      path: '/mercado-ganadero/mercado-de-cañuelas',
      component: PricesCañuelas,
      exact: true,
      showHeader: true
    },
    {
      path: '/mercado-ganadero/mercado-de-liniers',
      component: PricesLiniers,
      exact: true,
      showHeader: true
    },
    {
      path: '/mercado-ganadero/mercado-de-rosario',
      component: PricesRosario,
      exact: true,
      showHeader: true
    },
    {
      path: '/economia-politica',
      component: EconomicIndicators,
      exact: true,
      showHeader: true
    },
    {
      path: '/economia-politica/dolar',
      component: DolarPage,
      exact: true,
      showHeader: true
    },
    {
      path: '/economia-politica/bolsas',
      component: StocksPage,
      exact: true,
      showHeader: true
    },
    {
      path: '/economia-politica/monedas',
      component: CurrenciesPage,
      exact: true,
      showHeader: true
    },
    {
      path: '/granos/conversor-medidas',
      component: MeasurementConverter,
      exact: true,
      showHeader: true
    },
    {
      path: '/interes-general',
      component: GeneralInterest,
      exact: true,
      showHeader: true
    },
    {
      path: '/insumos-agropecuarios',
      component: FarmSupplies,
      exact: true,
      showHeader: true
    },
    {
      path: '/lecheria',
      component: Dairying,
      exact: true,
      showHeader: true
    },
    {
      path: '/economias-regionales',
      component: RegionalEconomies,
      exact: true,
      showHeader: true
    },
    {
      path: '/maquinarias-vehiculos',
      component: MachinesAndVehicles,
      exact: true,
      showHeader: true
    },
    {
      path: '/ganaderia',
      component: Ganaderia,
      exact: true,
      showHeader: true
    },
    {
      path: '/agtech',
      component: Agtech,
      exact: true,
      showHeader: true
    },
    {
      path: '/agricultura',
      component: Agriculture,
      exact: true,
      showHeader: true
    },
    {
      path: '/mercado-ganadero',
      component: Livestock,
      exact: true,
      showHeader: true
    },
    {
      path: '/economia-finanzas',
      component: EconomyAndFinances,
      exact: true,
      showHeader: true
    },
    {
      path: '/tags/:tagName',
      component: TagsPage,
      exact: true,
      showHeader: true
    },
    {
      path: '/newsletter',
      component: NewsletterPage,
      exact: true,
      showHeader: true
    },
    {
      path: '/expo/expoagro',
      component: ExpoAgro,
      exact: true,
      showHeader: true
    },
    {
      path: '/search/:filtros',
      component: SearchResults,
      exact: true,
      showHeader: true
    },
    {
      path: '/terminos-y-condiciones',
      component: TermsOfUse,
      exact: true,
      showHeader: true
    },
    {
      path: '/live',
      component: AgrofyNewsLive,
      exact: true,
      showHeader: true
    },
    {
      path: '/tv',
      component: AgrofyTv,
      exact: true,
      showHeader: true
    },
    {
      path: '/tv/streamings/:streamingTitle',
      component: Streaming,
      exact: true,
      showHeader: true
    },
    {
      path: '/tv/:idVideo/:videoTitle',
      component: AgrofyTv,
      exact: true,
      showHeader: true
    },
    {
      path:'/expo/aapresid',
      component: AapresidConference,
      exact: true,
      showHeader: true
    },
    {
      path:'/cadena3',
      component: Cadena3,
      exact: true,
      showHeader: false
    },
    {
      path:'/expo/agroactiva',
      component: Agroactiva,
      exact: true,
      showHeader: true
    },
    {
      path:'/expo/todo-trigo',
      component: TodoTrigo,
      exact: true,
      showHeader: true
    },
    {
      path:'/partners/corteva',
      component: Corteva,
      exact: true,
      showHeader: true
    },
    {
      path:'/partners/basf',
      component: Basf,
      exact: true,
      showHeader: true
    },
    {
      path:'/partners/metalfor',
      component: Metalfor,
      exact: true,
      showHeader: true
    },
    {
      path:'/partners/yara',
      component: Yara,
      exact: true,
      showHeader: true
    },
    {
      path:'/casos-de-exito',
      component: SuccessCases,
      exact: true,
      showHeader: true
    },
    { 
      path: '/tv/:listaVideos',
      component: Categories,
      exact: true,
      showHeader: true
    },
    {
	    path: '/autores/:authorSlug',
	    component: AuthorPage,
	    exact: true,
	    showHeader: true
	  },
    {
      path: '/indices',
      component: IndicesAgrofy,
	    exact: true,
	    showHeader: true
    },
    // This is 404 page, it must be the last one on the list
    {
      component: NotFound,
      exact: true,
      showHeader: true,
      hideTopics: true
    }
  ],
  ptBR: [
    {
      path: '/',
      component: Home,
      exact: true,
      showHeader: true
    },
    { 
      path: '/previsao-clima-tempo',
      component: ClimaPronostico,
      exact: true,
      showHeader: true
    },
    { 
      path: '/previsao-clima-tempo/cidade/:city',
      component: ClimaPronostico,
      exact: true,
      showHeader: true
    },    
    {
      path: '/agricultura',
      component: Agriculture,
      exact: true,
      showHeader: true
    },
    { 
      path: '/pecuaria',
      component: Ganaderia,
      exact: true,
      showHeader: true
    },
    { 
      path: '/economia',
      component: RegionalEconomies,
      exact: true,
      showHeader: true
    },
    { 
      path: '/politica',
      component: EconomicIndicators,
      exact: true,
      showHeader: true
    },
    { 
      path: '/agtech',
      component: Agtech,
      exact: true,
      showHeader: true
    },
    { 
      path: '/tecnologia-agropecuaria',
      component: MachinesAndVehicles,
      exact: true,
      showHeader: true
    },
    { 
      path: '/insumos-agropecuarios',
      component: FarmSupplies,
      exact: true,
      showHeader: true
    },
    { 
      path: '/atualidades',
      component: GeneralInterest,
      exact: true,
      showHeader: true
    },
    {
      path: '/autores/:authorSlug',
      component: AuthorPage,
      exact: true,
      showHeader: true
    },
    {
      path: '/search/:filtros',
      component: SearchResults,
      exact: true,
      showHeader: true
    },
    {
      path: '/cotacoes',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/cotacoes/preco-soja',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/cotacoes/preco-cafe',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/cotacoes/preco-trigo',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/cotacoes/preco-milho',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/cotacoes/preco-algodao',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/cotacoes/preco-laranja',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/cotacoes/preco-boi-gordo',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/cotacoes/preco-frango',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/cotacoes/preco-suino',
      component: Granos,
      exact: true,
      showHeader: true
    },
    {
      path: '/tv',
      component: AgrofyTv,
      exact: true,
      showHeader: true
    },
    { 
      path: '/tv/:listaVideos',
      component: Categories,
      exact: true,
      showHeader: true
    },
    {
      path: '/tv/:idVideo/:videoTitle',
      component: AgrofyTv,
      exact: true,
      showHeader: true
    },
    {
      path: '/tags/:tagName',
      component: TagsPage,
      exact: true,
      showHeader: true
    },
    {
      path: '/termos-e-condicoes',
      component: TermsOfUseBR,
      exact: true,
      showHeader: true,
      hideTopics: true
    },
    // This is 404 page, it must be the last one on the list
    {
      component: NotFound,
      exact: true,
      showHeader: true,
      hideTopics: true
    }
  ]
};

export default routes;
