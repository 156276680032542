/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react';
// import { useTranslation } from 'react-i18next';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import {openNewTab} from '../../components/tagsRow/TagsRow';

const MainMenu = ({data, topics}) => {
  const { t } = useTranslation();
  
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const newslettersUrl = isBrasil ? null : "/newsletter";
  
  const manageGTM = label => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'trackEvent',
        eventCategory: 'Navigation',
        eventAction: 'Agrofy News-Hamburger Menu',
        eventLabel: label
      }
    };
    
    TagManager.dataLayer(tagManagerArgs);
  };

  const handleNewslettersClick = () => {
    if (isBrasil) {
      const input = document.getElementById('mailInput');

      input.scrollIntoView({behavior: "smooth", block: "center"});
      typeof window !== 'undefined' && window.setTimeout(() => input.focus({preventScroll: true}), 0);
    }
  };

  const getDropdown = item => {
    return (
      <Dropdown className="has-child" key={`main-menu-${item.id}`}>
        <Dropdown.Toggle>
          {item.full_name || item.name}
        </Dropdown.Toggle>
        <div className="level-1 dropdown-menu" aria-labelledby="">
          {item.childrens.map((child, idx) => <Link onClick={() => manageGTM(`${item.name}-${child.name}`)} key={`main-menu-child-${child.id}-${idx}`} to={child.url}>{child.full_name || child.name}</Link>)}
        </div>
      </Dropdown>
    );
  };

  const isExternalSection = item => 
    ['Foro', 'Trigo 2020/21'].includes(item.name) ||
    ['/foro'].includes(item.url) ||
    item.url.startsWith("/especiales") || 
    item.url.startsWith('/feriados');

  const duplicateElementsList = (allTopics, allSections) => allTopics.filter(o1 => allSections.some(o2 => o1.title === o2.name));
  
  const renderCustomTag = (item, idx) => {
    return item.external > 0 ? <a href={`/${item.url}`} target={openNewTab(item)} rel="noopener noreferrer" key={`main-menu-${item.id}-${idx}`}>{item.title}</a> : <Link key={`main-menu-${item.id}-${idx}`} onClick={() => manageGTM(item.title)} to={`/${item.url}`} target={openNewTab(item)}>{item.title}</Link>;
  };
  
  const topicsList = (allTopics, allSections) => allTopics.filter(el => !duplicateElementsList(allTopics, allSections).includes(el));

  const newslettersOption = 
    <Link 
      key={`main-menu-newsletters`}
      onClick={() => { manageGTM('Suscribirme a Newsletters'); handleNewslettersClick()}}
      to={newslettersUrl}
      target="_self">
        {t('newsletter_subscribe')}
    </Link>;

  return (
    <Nav className="mr-auto">
      {
        // eslint-disable-next-line no-confusing-arrow
        data.map((item, idx) =>
          item.has_children > 0 ?
            getDropdown(item) :
            isExternalSection(item) ?
              <a onClick={() => manageGTM(item.name)} href={`${item.url}`} key={`main-menu-${item.id}-${idx}`} target={item.url.includes('/especiales/') ? 'blank' : ''}>{item.full_name || item.name}</a> :
              <Link onClick={() => manageGTM(item.name)} key={`main-menu-${item.id}-${idx}`} to={item.url}>{item.full_name || item.name}</Link>
        )
      }
      { newslettersOption }
    </Nav>
  );
};

MainMenu.defaultProps = {
  data: [
    {
      childrens: [],
      destacado: 0,
      has_children: 0,
      hidden: 0,
      id: 1192,
      name: 'Interes General',
      parent: 0,
      url: '/interes-general'
    },
    {
      childrens: [{
        destacado: false,
        has_children: 0,
        hidden: false,
        id: 1198,
        name: 'Mercados',
        parent: 1197,
        url: '/ganaderia/mercados'
      }],
      destacado: 0,
      has_children: 1,
      hidden: 0,
      id: 1197,
      name: 'Ganadería',
      parent: 0,
      url: '/ganaderia'
    }
  ]
};

MainMenu.propTypes = {
  data: PropTypes.array
};
export default MainMenu;
