import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';

const TopMenu = ({data, extSection}) => {
  const manageGTM = label => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'trackEvent',
        eventCategory: 'Navigation',
        eventAction: 'Agrofy News-Menu Header',
        eventLabel: `${label}`
      }
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  const getDropdown = item => {
    return (
      <Dropdown className="has-child" key={`top-menu-drop-${item.id}`}>
        <Dropdown.Toggle className="link-item">
          {item.name}
        </Dropdown.Toggle>
        <Dropdown.Menu className="level-1">
          {item.childrens.map(child =>
            <Link
              key={`top-menu-drop-child-${child.id}`}
              to={child.url}
              onClick={() => manageGTM(`${item.name}-${child.name}`)}>{child.name}</Link>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const isExternalSection = item => 
    ['Foro', 'Trigo 2020/21'].includes(item.name) ||
    ['/foro'].includes(item.url) ||
    item.url.startsWith("/especiales") || 
    item.url.startsWith('/feriados');

  const getLinks = item => {
    return (
      isExternalSection(item) ?
        <div className="no-child" key={`top-menu-${item.id}`}>
          <a href={`${item.url}`} onClick={() => manageGTM(item.name)} target={item.url.includes('/especiales/') ? 'blank' : ''} className="link-item">{item.name}</a>
        </div> :
        <div className="no-child" key={`top-menu-${item.id}`}>
          <Link className="link-item" to={item.url} onClick={() => manageGTM(item.name)}>{item.name}</Link>
        </div>
    );
  };

  return (
    <React.Fragment>{
      // eslint-disable-next-line no-confusing-arrow
      data.map(item => item.has_children > 0
        ? getDropdown(item)
        : getLinks(item)
      )
    }
    </React.Fragment>
  );
};
TopMenu.defaultProps = {
  data: [
    {
      childrens: [],
      destacado: 1,
      has_children: 0,
      hidden: 0,
      id: 1192,
      name: 'Interes General',
      parent: 0,
      url: '/interes-general'
    },
    {
      childrens: [{
        destacado: false,
        has_children: 0,
        hidden: false,
        id: 1198,
        name: 'Mercados',
        parent: 1197,
        url: '/ganaderia/mercados'
      }],
      destacado: 1,
      has_children: 1,
      hidden: 0,
      id: 1197,
      name: 'Ganadería',
      parent: 0,
      url: '/ganaderia'
    }
  ]
};

TopMenu.propTypes = {
  data: PropTypes.array,
  extSection: PropTypes.object
};
export default TopMenu;
