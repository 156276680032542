import React, {useState, useEffect} from 'react';
import RainsTabContent from '../../../atoms/RainsTabContent/RainsTabContent';
import getData from '../../../services/GetData';
import {Tabs, Tab} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import './RainTabs.scss';

function RainTabs() {
  const {t} = useTranslation();
  const [provincesData, setProvincesData] = useState();

  useEffect(() => {
    const fetchData = () => {
      getData({
        url: `${window.env.RAZZLE_ENDPOINT_NEWS_API}/api/Weather/GetRainTracking`
      }).then(resp => setProvincesData(resp.data));
    };
    fetchData();
  }, []);

  const loader = <div className="loader container px-0"><div className="animated-background" /></div>;

  return (
    <div className="rains-tabs-wrapper mt-10">
      {!provincesData ? loader :
        <Tabs className="basic-tabs" transition={false} >
          <Tab eventKey={t('bsas')} title={t('bsas')}>
            <RainsTabContent
              rainsMap = {`${window.env.RAZZLE_ENDPOINT_NEWS}/${provincesData.bsas_map}`} mapAlt = {`${t('map_of')} ${t('bsas')}`}
              refImg = {`${window.env.RAZZLE_ENDPOINT_NEWS}/${provincesData.maps_ref}`} refAlt = {`${t('reference_image')}`}
              table = {provincesData.bsas}
            />
          </Tab>
          <Tab eventKey={t('cba')} title={t('cba')}>
            <RainsTabContent
              rainsMap={`${window.env.RAZZLE_ENDPOINT_NEWS}/${provincesData.cordoba_map}`} mapAlt={`${t('map_of')} ${t('cba')}`}
              refImg = {`${window.env.RAZZLE_ENDPOINT_NEWS}/${provincesData.maps_ref}`} refAlt = {`${t('reference_image')}`}
              table = {provincesData.cordoba}
            />
          </Tab>
          <Tab eventKey={t('entre_rios')} title={t('entre_rios')}>
            <RainsTabContent
              rainsMap={`${window.env.RAZZLE_ENDPOINT_NEWS}/${provincesData.entrerios_map}`} mapAlt={`${t('map_of')} ${t('entre_rios')}`}
              refImg = {`${window.env.RAZZLE_ENDPOINT_NEWS}/${provincesData.maps_ref}`} refAlt = {`${t('reference_image')}`}
              table = {provincesData.entrerios}
            />
          </Tab>
          <Tab eventKey={t('pampa')} title={t('pampa')}>
            <RainsTabContent
              rainsMap={`${window.env.RAZZLE_ENDPOINT_NEWS}/${provincesData.pampa_map}`} mapAlt={`${t('map_of')} ${t('pampa')}`}
              refImg = {`${window.env.RAZZLE_ENDPOINT_NEWS}/${provincesData.maps_ref}`} refAlt = {`${t('reference_image')}`}
              table = {provincesData.pampa}
            />
          </Tab>
          <Tab eventKey={t('stafe')} title={t('stafe')}>
            <RainsTabContent
              rainsMap={`${window.env.RAZZLE_ENDPOINT_NEWS}/${provincesData.santafe_map}`} mapAlt={`${t('map_of')} ${t('stafe')}`}
              refImg = {`${window.env.RAZZLE_ENDPOINT_NEWS}/${provincesData.maps_ref}`} refAlt = {`${t('reference_image')}`}
              table = {provincesData.santafe}
            />
          </Tab>
        </Tabs>
      }
    </div>
  );
}

export default RainTabs;
