import React, {useState, useEffect} from 'react';
import getData from '../../services/GetData';
import {useTranslation} from 'react-i18next';
import TagManager from 'react-gtm-module';
import './SearchBar.scss';

const SearchBar = () => {
  const {t} = useTranslation();
  const [query, setQuery] = useState("");
  const [marketResp, setMarketResp] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const agrofyUrl = 'https://www.agrofy.com.ar/';
  const isEmpty = query === '' || query.length < 2;
  const marketQuery = query && !isEmpty ? query
    .trim()
    .toLowerCase()
    .replace(/[ ] +/g, ' ')
    .replace(/[/ ]/g, '-')
    .replace(/[#]/g, '') : '';

  const manageGTM = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'searchAgrofyNews',
        category: 'Marketplace-Products List',
        term: `${query}`
      }
    };
    
    TagManager.dataLayer(tagManagerArgs);
  };

  const fetchData = (marketQuery) => {
    const url = `https://apigateway-argentina.agrofy.com/api/suggestions?words=${marketQuery}&size=25`;
    getData({url}).then(resp => setMarketResp(resp.data));
  };

  useEffect(() => {
    if (marketQuery != '' && !isEmpty && searchSuggestions) {
      fetchData(marketQuery);
    } else {
      setSuggestions([]);
      setSearchSuggestions(false);
    }
  }, [marketQuery, query]);

  useEffect(() => {
    if (marketResp) {
      setSuggestions(getSuggestions(marketResp.Content));
    }
  }, [marketResp]);

  useEffect(() => {
    const url = `${agrofyUrl}${formatQuery(selectedSuggestion)}`;
    selectedSuggestion !== "" && window.open(url, '_blank');
  }, [selectedSuggestion]);

  const formatQuery = query => {
    return query
      .trim()
      .toLowerCase()
      .replace(/[ ] +/g, ' ')
      .replace(/[/ ]/g, '-')
      .replace(/[#]/g, '');
  };

  const getSuggestions = () => {
    var list = [];

    if (marketResp) {
      list.push({
        'type': 'agrofy_online_branches',
        'suggestions': filterFunc(marketResp.Content.agroshopname_suggester)
      });

      list.push({
        'type': 'categories',
        'suggestions': filterFunc(marketResp.Content.categoryname_suggester)
      });

      var categories = list.find(x => x.type == 'categories');

      if (categories && categories.length < 3) {
        categories.suggestions.push(
          ...filterFunc(marketResp.Content.main_category_name_suggester)
        );
      }
      else if (!categories || categories.length == 0) {
        list.push({
          'type': 'categories',
          'suggestions': filterFunc(marketResp.Content.main_category_name_suggester)
        });
      }

      if (categories && categories.length < 3) {
        categories.suggestions.push(
          ...filterFunc(marketResp.Content.submain_category_name_suggester)
        );
      }
      else if (!categories || categories.length == 0) {
        list.push({
          'type': 'categories',
          'suggestions': filterFunc(marketResp.Content.submain_category_name_suggester)
        });
      }
    }

    return list;
  };

  const filterFunc = (object) => {
    var suggestions = [];

    if (object.Found > 0 && object.Found > 4) {
      Object.values(object.Suggestions.slice(0, 3)).forEach(item => {
        suggestions.push(item.Suggestion);
      });
    }
    else if (object.Found > 0 && object.Found < 3) {
      Object.values(object.Suggestions).forEach(item => {
        suggestions.push(item.Suggestion);
      });;
    }

    return suggestions;
  };

  const handleClick = event => {
    setQuery(event.target.innerText);
    setSelectedSuggestion(event.target.innerText);
    setSearchSuggestions(false);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !isEmpty) {
      const url = `${agrofyUrl}${marketQuery}`;
      window.open(url, '_blank');
      manageGTM();
    }

    if (!isEmpty) {
      setSearchSuggestions(true);
    }
  };

  const handleOnBlur = (e) => {
    setSearchSuggestions(false);
  };

  const handleOnFocus = () => {
    setSearchSuggestions(true);
  };

  const dropDown = (element, index) => {
    return element && element.suggestions.length > 0 && searchSuggestions ?
    <div>
      <div className="title-container">
        <label className="suggestions-title">{t(element.type)}</label>
        {index === 0 ? <div className="icon"/> : ""}
      </div>
      <ul className="suggestions-list">
        {element.suggestions.map((suggestion, inx) => (
          <li key={inx} onMouseDown={(e) => handleClick(e, suggestion)}>{suggestion}</li>
        ))}
      </ul>
    </div> : ""
  }

  return (
    <div>
      <div id="search-bar">
        <input
          className="form-control border-0 pr-0"
          type="text"
          name="search-input"
          onBlur={(e) => handleOnBlur(e)}
          onFocus={() => handleOnFocus()}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={e => handleKeyDown(e)}
          value={query}
          placeholder={t('search_supplies')}
          autoComplete="off" />
        <a
          href={`${agrofyUrl}${marketQuery}`}
          className="search-icon"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => manageGTM()}
        >
          <span className="search-icon"><i className={isEmpty ? 'magnifierIconDisabled' : 'magnifierIcon'}></i></span>
        </a>
        <div className="suggestions">
          {suggestions.map((item, index) => dropDown(item, index))}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
