import React, { useState, useEffect } from 'react';
import getData from '../../services/GetData';
import { Link } from 'react-router-dom';
import { AgroLink, Box } from 'agrofy-ui-components';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import AgrofyPayLogo from '../Icons/AgrofyPayLogo';
import CortevaLogo from '../Icons/CortevaLogo';
import './DailyWeather.scss';

export const DailyWeather = ({weatherData, weatherDaysToDisplay = 3, weatherShowSponsoredBy,weatherShowSponsoredByBR}) => {
  const { t } = useTranslation(['translation', 'links']);
  const loader = (
    <div className="loader container pr-0 min-height">
      <div className="animated-background" />
    </div>
  );
  const [isLoading, setIsLoading] = useState(true);

  const language = process.env.RAZZLE_LANGUAGE || window.env.RAZZLE_LANGUAGE;
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === 'Brasil';
  
  const [savedCityName, setSavedCityName] = useState(false);
  const [savedCitySlug, setSavedCitySlug] = useState(false);

  const showSponsoredBy = isBrasil ? weatherShowSponsoredByBR : weatherShowSponsoredBy;

  const defaultCitySlug = isBrasil ? 'brasilia+df' : 'rosario+santa+fe';
  const defaultCityName = isBrasil ? 'Brasilia' : 'Rosario';

  useEffect(() => {
    setSavedCityName(JSON.parse(localStorage.getItem('savedCityName')) || defaultCityName);
    setSavedCitySlug(JSON.parse(localStorage.getItem('savedCitySlug')) || defaultCitySlug);
  }, []);

  useEffect(() => {
    setIsLoading(!weatherData);
  }, [weatherData]);

  function formatDate(date, num) {
    // num is the number of days afater today
    const d = date;
    d.setDate(d.getDate() + num);
    const options = { weekday: 'long' };

    var dateString = date.toLocaleDateString(language, options);

    if (isBrasil) {
      dateString = dateString.replace('-feira', '');
    }

    return dateString;
  }

  const manageGTM = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'trackEvent',
        eventCategory: 'Navigation',
        eventAction: 'Agrofy News - Indicadores Home',
        eventLabel: 'Clima',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  return (
    <Link
      to={`${t('links:city_weather')}${savedCitySlug}`}
      onClick={() => manageGTM()}
    >
      {isLoading ? (
        loader
      ) : (
        <React.Fragment>
          <div className={`component daily-weather ${showSponsoredBy ? 'show-sponsored' : ''}`}>
          {showSponsoredBy && (
            <div className="sponsored-by">
              <span className="sponsored-by-text">{t('sponsored-by')}</span>
              {isBrasil ? <CortevaLogo height="16px" width="95px" /> : <AgrofyPayLogo height="16px" width="95px" />}
            </div>
          )}
            <Box
              display="flex"
              justify-content="space-between"
              className={`headingContainer ${showSponsoredBy ? 'show-sponsored' : ''}`} >
              <span className="title heading">
                {t('translation:weather_at')}{' '}
                <span className="city heading">{savedCityName}</span>
              </span>
              <AgroLink size="lg" className={`card-view-more vm-weather `}>
                {t('translation:view_more')}
              </AgroLink>
            </Box>
            <div className={'daily-item clima'}>
              <ul>
                {
                  weatherData && weatherData.map((item, idx) =>
                    weatherDaysToDisplay > idx ?
                      <li className={idx < weatherData.length - 2 ? '' : 'weather-display-none'}>
                        <label>{idx == 0 ? t('translation:today') : formatDate(new Date(), idx)}</label>
                        <h6>
                          {`${Math.round(
                            item.Temperature.Minimum.Value
                          )}° / ${Math.round(item.Temperature.Maximum.Value)}°`}
                        </h6>
                        <i
                          className={`clima-icon main-icon icon-${item.Day.Icon}`}
                        />
                      </li>
                    : null
                  )
                }
              </ul>
            </div>
          </div>
        </React.Fragment>
      )}
    </Link>
  );
};

export default DailyWeather;
